import { staticPath } from '@/helpers'

import Strings from './strings'
// Routes
export const Routes = {
  // Generic
  Login: '/',
  SignUp: '/signup',
  MyProfile: '/profile',
  CompanyProfile: '/p/',
  EngagementDetails: '/engagement/:engagementId',
  EngagementDetailSlug: (engagementId: string): string => `/engagement/${engagementId}`,
  EngagementDocuments: '/engagement/:engagementId/documents',
  EngagementDocumentsSlug: (engagementId: string): string =>
    `/engagement/${engagementId}/documents`,
  EngagementMessages: '/engagement/:engagementId/messages',
  EngagementMessagesSlug: (engagementId: string): string => `/engagement/${engagementId}/messages`,
  EngagementNotes: '/engagement/:engagementId/notes',
  EngagementNotesSlug: (engagementId: string): string => `/engagement/${engagementId}/notes`,
  EngagementTodos: '/engagement/:engagementId/todos',
  EngagementTodosSlug: (engagementId: string): string => `/engagement/${engagementId}/todos`,
  VerifyEmail: '/verify-email',
  ResetPassword: '/reset-password',
  ResetPasswordChange: '/reset-password-change',
  Logout: '/logout',
  FourOhFour: '/404',
  DevTools: '/_devtools',
  PlansAndPricing: '/plans-and-pricing',
  Profile: '/account-settings',
  ProfileSubscribe: '/account-settings/subscribe',
  ProfileCompanyDetails: '/account-settings/company-details',
  ProfilePaymentSettings: '/account-settings/payment-settings',
  ProfileYourInformation: '/account-settings/your-information',
  ProfileChangePassword: '/account-settings/change-password',
  ProfileTeam: '/account-settings/team',
  Subscription: '/account-settings/subscription',
  CompanyProfileSlug: (slug: string): string => `/p/${slug}`,
  Search: '/_search',
  Engagements: '/engagements',
  BrandMatchesListingSlug: (projectId: string): string => `/project/${projectId}/matches`,
  ProjectIntakeNewProject: '/projects/new',
  ProjectIntakeLanding: (projectId: string): string => `/projects/${projectId}/landing`,
  ProjectIntakeReview: (projectId: string): string => `/projects/${projectId}/edit/review`,
  TeamInviteAccept: '/invites/:token/accept',
  Leads: '/leads',
  LeadJoin: '/lm-join',
  Marketplace: '/marketplace',
}

export const Assets = {
  technicalReviewGuidePdf: staticPath('/assets/Technical Review.pdf'),
}

export const Images = {
  peopleChatting: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/people-chatting.svg')
    : staticPath('/images/people-chatting.svg'),
  intraBrandMessagesEmpty: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/intra_brand_empty.svg')
    : staticPath('/images/intra_brand_empty.svg'),
  intraComanMessagesEmpty: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/intra_coman_empty.svg')
    : staticPath('/images/intra_coman_empty.svg'),
  interMessagesEmpty: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/inter_messages_empty.svg')
    : staticPath('/images/inter_messages_empty.svg'),
  searchIcon: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/search.png')
    : staticPath('/images/search.png'),
  searchPressed: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/search-pressed.png')
    : staticPath('/images/search-pressed.png'),
  success: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/check@2x.png')
    : staticPath('/images/check@2x.png'),
  triangle: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/triangle@2x.png')
    : staticPath('/images/triangle@2x.png'),
  logo: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/logo.png')
    : staticPath('/images/logo.png'),
  whiteLogo: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/white_logo.svg')
    : staticPath('/images/white_logo.svg'),
  successCheck: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/success_check.png')
    : staticPath('/images/success_check.png'),
  brandStageEmerging: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/brand_stage_emerging.png')
    : staticPath('/images/brand_stage_emerging.png'),
  brandStageExploring: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/brand_stage_exploring.png')
    : staticPath('/images/brand_stage_exploring.png'),
  brandStageLegacy: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/brand_stage_legacy.png')
    : staticPath('/images/brand_stage_legacy.png'),
  brandStageStartup: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/brand_stage_startup.png')
    : staticPath('/images/brand_stage_startup.png'),
  companyTypeBrand: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/company_type_brand.png')
    : staticPath('/images/company_type_brand.png'),
  companyTypeBusinessServices: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/company_type_business_services.png')
    : staticPath('/images/company_type_business_services.png'),
  companyTypeManufacturer: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/company_type_manufacturer.png')
    : staticPath('/images/company_type_manufacturer.png'),
  serviceTypeLabel: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/service_type_label.png')
    : staticPath('/images/service_type_label.png'),
  serviceTypeManufacturer: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/service_type_manufacturer.png')
    : staticPath('/images/service_type_manufacturer.png'),
  serviceTypePackager: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/service_type_packager.png')
    : staticPath('/images/service_type_packager.png'),
  signupBackground: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/signup_background.png')
    : staticPath('/images/signup_background.png'),
  loginBackground: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/login-background.png')
    : staticPath('/images/login-background.png'),
  arrow: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/arrow.svg')
    : staticPath('/images/arrow.svg'),
  leftArrow: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/left-arrow.svg')
    : staticPath('/images/left-arrow.svg'),
  closeIcon: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/close_icon.png')
    : staticPath('/images/close_icon.png'),
  closeIconLightbox: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/close-icon-lightbox.png')
    : staticPath('/images/close-icon-lightbox.png'),
  mail: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/mail.svg')
    : staticPath('/images/mail.svg'),
  alert: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/alert.png')
    : staticPath('/images/alert.png'),
  lock: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/lock.svg')
    : staticPath('/images/lock.svg'),
  info: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/info.svg')
    : staticPath('/images/info.svg'),
  spinner: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/spinner.png')
    : staticPath('/images/spinner.svg'),
  serviceType: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/service_type.png')
    : staticPath('/images/service_type.png'),
  termsAndServices: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/terms_and_services.png')
    : staticPath('/images/terms_and_services.png'),
  editPencil: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/icon-edit.png')
    : staticPath('/images/icon-edit.png'),
  trashcan: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/trash-icon.png')
    : staticPath('/images/trash-icon.png'),
  frame: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/frame.png')
    : staticPath('/images/frame.png'),
  blackX: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/black-x.png')
    : staticPath('/images/black-x.png'),
  blackThinX: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/thin-black-X.png')
    : staticPath('/images/thin-black-X.png'),
  user: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/user.png')
    : staticPath('/images/user.png'),
  users: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/users.svg')
    : staticPath('/images/users.svg'),
  camera: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/camera.svg')
    : staticPath('/images/camera.svg'),
  error: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/error.png')
    : staticPath('/images/error.png'),
  briefcase: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/briefcase.svg')
    : staticPath('/images/briefcase.svg'),
  shoppingBag: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/shopping-bag.svg')
    : staticPath('/images/shopping-bag.svg'),
  check: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/check.svg')
    : staticPath('/images/check.svg'),
  angleLeft: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/angle-left.png')
    : staticPath('/images/angle-left.png'),
  angleLeftWhite: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/angle-left-white.png')
    : staticPath('/images/angle-left-white.png'),
  angleRight: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/angle-right.png')
    : staticPath('/images/angle-right.png'),
  angleRightWhite: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/angle-right-white.png')
    : staticPath('/images/angle-right-white.png'),
  bannerPlaceholder: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/banner-placeholder.png')
    : staticPath('/images/banner-placeholder.png'),
  award: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/award.png')
    : staticPath('/images/award.png'),
  barChart: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/bar-chart.png')
    : staticPath('/images/bar-chart.png'),
  clock: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/clock.png')
    : staticPath('/images/clock.png'),
  globe: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/globe.png')
    : staticPath('/images/globe.png'),
  home: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/home.png')
    : staticPath('/images/home.png'),
  list: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/list.png')
    : staticPath('/images/list.png'),
  mapPin: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/map-pin.png')
    : staticPath('/images/map-pin.png'),
  package: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/package.png')
    : staticPath('/images/package.png'),
  pieChart: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/pie-chart.png')
    : staticPath('/images/pie-chart.png'),
  plusCircle: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/plus-circle.png')
    : staticPath('/images/plus-circle.png'),
  calendar: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/calendar.png')
    : staticPath('/images/calendar.png'),
  truck: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/truck.png')
    : staticPath('/images/truck.png'),
  send: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/send.svg')
    : staticPath('/images/send.svg'),
  stack: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/stack.png')
    : staticPath('/images/stack.png'),
  toastSuccess: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/toast-success.png')
    : staticPath('/images/toast-success.png'),
  toastError: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/toast-error.png')
    : staticPath('/images/toast-error.png'),
  toastClose: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/toast-close.png')
    : staticPath('/images/toast-close.png'),
  fourOhFour: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/404.png')
    : staticPath('/images/404.png'),
  book: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/Book.png')
    : staticPath('/images/Book.png'),
  help: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/help-circle.png')
    : staticPath('/images/help-circle.png'),
  sliders: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/Sliders.png')
    : staticPath('/images/Sliders.png'),
  userCheck: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/User-check.png')
    : staticPath('/images/User-check.png'),
  folder: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/folder.png')
    : staticPath('/images/folder.png'),
  EngagementNDAWaiting: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/nda-waiting.png')
    : staticPath('/images/nda-waiting.png'),
  EngagementInactive: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-inactive.png')
    : staticPath('/images/engagement-inactive.png'),
  EngagementInactiveWithBackground: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-inactive-with-background.svg')
    : staticPath('/images/engagement-inactive-with-background.svg'),
  EngagementTRShare: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-tr-share.png')
    : staticPath('/images/engagement-tr-share.png'),
  EngagementTRIntroCall: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-tr-intro-call.png')
    : staticPath('/images/engagement-tr-intro-call.png'),
  EngagementTRMail: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-tr-mail-box.png')
    : staticPath('/images/engagement-tr-mail-box.png'),
  EngagementTRMagnify: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/engagement-tr-magnify.png')
    : staticPath('/images/engagement-tr-magnify.png'),
  SatelliteDish: STORYBOOK
    ? require('../../../../backend/partnerslate/static/images/satellite-dish.svg')
    : staticPath('/images/satellite-dish.svg'),
}

export const StaticLinks = {
  tos: 'https://partnerslate.com/wp-content/uploads/2023/03/PartnerSlate-Terms-of-Service.pdf',
  privacyPolicy:
    'https://s3-us-west-1.amazonaws.com/pslate.stage/docs/privacy-policy-partnerslate.pdf',
  help: 'http://help.partnerslate.com/',
  resourceNewBusiness: 'https://partnerslate.com/faqs',
}

export enum ProductTypes {
  BrandSupplierSubscription = 'brand_supplier_subscription',
  ProjectMarketplaceListing = 'project_marketplace_listing',
  ProjectMarketplaceListingQuickWin = 'project_marketplace_listing_quick_win',
}

export const Regex = {
  oneLower: /(?=.*[a-z])/,
  oneUpper: /(?=.*[A-Z])/,
  oneNumber: /.*[0-9].*/,
  oneSpecialChar: /(?=.*[~`!@#$%^&*()+=_\-{}[\]|:;"'?/<>,.])/,
}

export const Cookies = {
  auth: 'auth',
}

export const StorageHelperTypes = {
  cookie: 'cookie',
  local: 'local',
}

export const Metrics = {
  bannerAspectRatio: 5 / 1,
  profilePhoto: 1 / 1,
  morePhoto: 1.4 / 1,
  minProfileSize: { width: 300, height: 300 },
  minBannerSize: { width: 1500, height: 300 },
  minMoreSize: { width: 800, height: 800 },
}

export const CompanySearchDescriptionLimit = 341

export enum ImageTypes {
  profile = 'profile',
  companyProfile = 'company_profile',
  banner = 'banner',
  more = 'more',
}

export const MarketingLinks = {
  aboutSubscription: 'http://help.partnerslate.com/en/articles/5031808-partnerslate-plans',
}

export const AppContentID = 'app-content-id'

export default Strings
