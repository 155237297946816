/* eslint-disable react/jsx-no-bind */
import { EngagementStatusUpdatesModal } from '@partnerslate/feature-engagement-status-updates'
import { Navbar as Nav } from '@partnerslate/ui-components'
import { Company, User } from 'partnerslate-models'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useFeatureFlag } from '@/helpers/featureFlags'
import { FutureThemeProvider } from '@/helpers/mui'

type Props = {
  user: User
  company: Company
  isLoadingUser: boolean
}
export default function Navbar({ user, company, isLoadingUser }: Props): JSX.Element {
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isForcedOpen = params.get('forceOpenEngagementStatusUpdatesModal') === 'true'

  const showEngagementUpdatesModal = useFeatureFlag('show_latest_new_engagement_updates_modal')
  const showSearch = useFeatureFlag('show_search')
  // user initalizes with a blank User instead of null, so we need to do this check
  // as Navbar expects null to be empty user (not logged in)
  const currentUser = user.valid ? user : null
  // similar to User, Company is initalized with a blank Company that defaults
  // companyType to 'brand' which can be confusing when debugging
  const { companyType } = company

  const projects = currentUser?.projects || []
  const abridgedProjects = projects.map((project: User['projects'][number]) => {
    return {
      id: project.id,
      name: project.name,
      isPaid: project.is_paid,
    }
  })

  function router(to: string) {
    history.push(to)
  }

  const queryParams = new URLSearchParams(location.search)
  const utmSource = queryParams.get('utm_source')
  const utmMedium = queryParams.get('utm_medium')
  const useComanMarketingLingo = utmSource === 'ps_app' && utmMedium === 'mp_connect_me'

  // We create a new user object that matches the Nav component's expected type. This is
  // necessary because  the existing `User` type defines `User.companies` as a list of strings
  // instead of a list of Company objects due to some Redux normalization that happens when the
  // User object is initially created.
  const navUser = currentUser ? { ...currentUser, company, emailAddress: currentUser.email } : null

  return (
    <FutureThemeProvider>
      <Nav
        router={router}
        origin="main_repo"
        currentUser={navUser}
        isLoadingUser={isLoadingUser}
        companyType={companyType}
        showSearch={showSearch}
        projects={abridgedProjects}
        showHowItWorks={useComanMarketingLingo}
      />
      {/* We need to check for company.id since in this repo we get a default company without
      any real values, and that'd cause the modal to show up and trigger a infinite in the
      login page */}
      {showEngagementUpdatesModal && company.id ? (
        <EngagementStatusUpdatesModal company={company} isForcedOpen={isForcedOpen} />
      ) : null}
    </FutureThemeProvider>
  )
}
