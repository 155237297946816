/* eslint-disable max-lines */
/* eslint-disable camelcase */
import * as Sentry from '@sentry/react'
import { isValid, parseISO } from 'date-fns'

import {
  BoxAccessDetails,
  BrandEditableEngagementStages,
  ComanEditableEngagementStages,
  Engagement,
  EngagementParticipant,
  EngagementProject,
  MessageChannelConnection,
  Nda,
  NdaSigningState,
  ProjectDynamicAttrs,
  TodoKeysType,
} from '@/domain/engagements'
import {
  ComposedContentBlock,
  StructuredMessage,
  StructuredMessageChannelContents,
} from '@/domain/messages'
import { CheckoutPayload, CheckoutResponse } from '@/domain/stripe'
import { InvitedUser, InviteInformation, TeamMembers, User } from '@/domain/team'
import { UserAndCompany } from '@/redux/api-payloads'

import { Api, AxiosAdapterSuccessResponse } from './api'
import { BackendError } from './backendError'

const BOX_TOKEN_URL = `accounts/box-token/`
const ENGAGEMENT_URL = (engagementId: string) => `/engagements/${engagementId}/`
const SEND_NDA_REMINDER = (engagementId: string) =>
  `/engagements/${engagementId}/send-nda-reminder/`
const PARTNERED_ENGAGEMENTS_URL = `/engagements/partnered/`
const SEND_INVITE_URL = (companySlug: string) => `/companies/${companySlug}/send_invite/`
const INVITE_INFORMATION_URL = (token: string) => `/invites/${token}/`
const ACCEPT_SIGNUP_INVITE_URL = (token: string) => `/invites/${token}/accept/`
const RESEND_INVITE_URL = (inviteId: string) => `/our-invites/${inviteId}/resend/`
const CANCEL_INVITE_URL = (inviteId: string) => `/our-invites/${inviteId}/cancel/`
const TEAM_MEMBERS_URL = (companySlug: string) => `/companies/${companySlug}/team/`

const MESSAGING_CHANNEL_URL = (connection: MessageChannelConnection) =>
  `/messaging/channels/${connection.channelId}/`
const MESSAGING_CHANNEL_MESSAGES_URL = (connection: MessageChannelConnection) =>
  `/messaging/channels/${connection.channelId}/messages/`

const COMPLETED_ENGAGEMENT_TODO_URL = (engagementId: string) =>
  `/engagements/${engagementId}/completed-todos/`
// Note UPDATE_ENGAGEMENT_STATUS_URL will eventually replace CHANGE_ENGAGEMENT_STATUS_URL
const UPDATE_ENGAGEMENT_STATUS_URL = (engagementId: string) =>
  `/engagements/${engagementId}/update-status/`
const STRIPE_CHECKOUT_URL = 'customers/checkout/'
const BILLING_PORTAL_URL = 'customers/create_customer_portal_session/'

const parseEngagementParticipant = (companyData: any): EngagementParticipant => ({
  id: companyData.id,
  companyName: companyData.company_name,
  phone: companyData.company_phone,
  fullAddress: companyData.address,
  contactEmail: companyData.primary_contact?.email,
  contactName: `${companyData.primary_contact?.first_name} ${companyData.primary_contact?.last_name}`,
  slug: companyData.slug,
  website: companyData.website,
  calendlyUrl: companyData.calendly_url,
})

const parseEngagementProject = (projectData: any): EngagementProject => {
  return {
    ...parseProjectDynamicAttrs(projectData.dynamic_attrs),
    id: projectData.id,
    name: projectData.name,
    createdAt: new Date(projectData.created_at),
    marketplacePaidAt: new Date(projectData.marketplace_paid_at),
    publishStatus: projectData.publish_status,
    category: projectData.product_category,
    skus: projectData.skus,
    runSizeVolumePerSku: projectData.run_size_volume_per_sku,
    status: projectData.product_status,
    description: projectData.description,
    summary: projectData.summary,
    certificationRequirements: projectData.certification_requirements,
    annualVolumePerSku: projectData.annual_volume_per_sku,
    msrp: projectData.msrp,
    serviceModel: projectData.service_model,
    packagingFormat: projectData.packaging_format,
    specialEquipmentNeeded: projectData.special_equipment_needed,
    rAndDNeeded: projectData.r_and_d_needed,
    additionalNotes: projectData.additional_notes,
    allergensPresent: projectData.allergens_present,
    publishedToMarketplaceAt: projectData.published_to_marketplace_at
      ? new Date(projectData.published_to_marketplace_at)
      : null,
    yearFounded: projectData.year_founded,
    brandStage: projectData.brand_stage,
    address: {
      state: projectData.address.state,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseProjectDynamicAttrs = (dynamicAttrs: any): ProjectDynamicAttrs => {
  return {
    commercialFormula: dynamicAttrs.commercial_formula ?? null,
    aboutUs: dynamicAttrs.about_us ?? null,
    processAuthorityLetter: dynamicAttrs.process_authority_letter ?? null,
    ingredientsSourcing: dynamicAttrs.ingredients_sourcing ?? null,
    commercializingSupport: dynamicAttrs.commercializing_support ?? null,
    reasonToLookForComan: dynamicAttrs.reason_to_look_for_coman ?? null,
    formulationHelpLevel: dynamicAttrs.formulation_help_level ?? null,
    formulationHelpType: dynamicAttrs.formulation_help_type ?? null,
    formulationHelpDescription: dynamicAttrs.formulation_help_description ?? null,
    processingSteps: dynamicAttrs.processing_steps ?? null,
    openToAlternateProcessing: dynamicAttrs.open_to_alternate_processing ?? null,
    postProductionStorage: dynamicAttrs.post_production_storage ?? null,
    openToEquipmentInvestment: dynamicAttrs.open_to_equipment_investment ?? null,
    msrp: dynamicAttrs.msrp ?? null,
    packagingRequired: dynamicAttrs.packaging_required ?? null,
    packagingRequiredReasons: dynamicAttrs.packaging_required_reasons ?? null,
    hideMsrpFromListing: dynamicAttrs.hide_msrp_from_listing ?? true,
    restrictedIngredients: dynamicAttrs.restricted_ingredients ?? null,
    targetDateFirstRun: dynamicAttrs.target_date_first_run ?? null,
    additionalVolumeComments: dynamicAttrs.additional_volume_comments ?? null,
    needGFSIFacility: dynamicAttrs.need_gfsi_facility ?? null,
    geoPreference: dynamicAttrs.geo_preference ?? null,
    geoPreferenceImportance: dynamicAttrs.geo_preference_importance ?? null,
    serviceModel: dynamicAttrs.service_model ?? null,
    needComanFullfillment: dynamicAttrs.need_coman_fullfillment ?? null,
    comansToAvoid: dynamicAttrs.comans_to_avoid ?? null,
    distributedProduct: dynamicAttrs.distributed_product ?? null,
    currentDistributors: dynamicAttrs.current_distributors ?? null,
    currentNotableRetailers: dynamicAttrs.current_notable_retailers ?? null,
    currentDoorCount: dynamicAttrs.current_door_count ?? null,
    futureNotableRetailers: dynamicAttrs.future_notable_retailers ?? null,
    futureDistributors: dynamicAttrs.future_distributors ?? null,
    futureDoorCount: dynamicAttrs.future_door_count ?? null,
    typeOfPartnership: dynamicAttrs.type_of_partnership ?? [],
    needSpecialtyIngredientsSourcing: dynamicAttrs.need_specialty_ingredients_sourcing ?? null,
    kindsOfSpecialtyIngredients: dynamicAttrs.kinds_of_specialty_ingredients ?? [],
    otherKindOfSpecialtyIngredients: dynamicAttrs.other_kind_of_specialty_ingredients ?? null,
    anyIngredientsRequirement: dynamicAttrs.any_ingredients_requirement ?? null,
  }
}

const parseBoxAccessDetails = (data: any): BoxAccessDetails | null => {
  const { box_folder_id } = data
  if (box_folder_id) {
    return {
      folderId: box_folder_id,
    }
  }
  return null
}

const parseMessageChannelConnection = (channelId: any): MessageChannelConnection | null => {
  if (!channelId) {
    return null
  }

  return {
    channelId,
  }
}

const parseNda = (nda: any): Nda | null => {
  if (!nda) {
    return null
  }

  return {
    signingUrl: nda.signing_url,
    signingState: nda.signing_state,
    signingMethod: nda.signing_method,
    pdfUrl: nda.pdf_url,
    ndaSource: nda.nda_source,
    signers: nda.signers,
    remindedAt: nda.reminded_at ? new Date(nda.reminded_at) : null,
  }
}

/**
 * This will return `unsigned` for engagements that have been recently created (e.g. proposal accepted)
 * and we get in the wire a `spawning` status and nda_stage of `no_nda`. This same logic applies
 * for engagements with off-platform NDAs
 */
const handleNoNda = (
  ndaStage: NdaSigningState,
  onWireStage: ComanEditableEngagementStages | BrandEditableEngagementStages | 'nda' | 'spawning',
): NdaSigningState => {
  if (ndaStage === 'no_nda' && onWireStage === 'spawning') {
    return 'unsigned'
  }
  return ndaStage
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseEngagementPayload = (data: any): Engagement => {
  const brand: EngagementParticipant = parseEngagementParticipant(data.brand)
  const coman: EngagementParticipant = parseEngagementParticipant(data.partner)
  const project: EngagementProject = parseEngagementProject(data.project)

  const boxAccessDetails: BoxAccessDetails | null = parseBoxAccessDetails(data)
  const intraTeamMessages = parseMessageChannelConnection(data.intra_chat_channel_id)
  const interTeamMessages = parseMessageChannelConnection(data.cross_chat_channel_id)

  const onWireStage = data.stage

  return {
    id: data.id,
    createdAt: new Date(data.created_at),
    perspective: data.perspective,
    // Following similar anti-corruption pattern introduced in monorepo
    // https://github.com/PartnerSlate/ts-monorepo/blob/64860cce4c13b2c37556c123c5e1fc4d366f06d0/libs/segment/engagements/src/api/parsers.ts#L37
    stage: onWireStage === 'spawning' ? 'nda' : data.stage,
    ndaStage: handleNoNda(data.nda_stage, onWireStage),
    nda: parseNda(data.nda),
    brand,
    coman,
    project,
    boxAccessDetails,
    intraTeamMessages,
    interTeamMessages,
    completedTodos: data.completed_todos,
  }
}

const parseMembers = (members: any): User[] => {
  return members.map((user: any) => {
    return {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      jobTitle: user.job_title,
      email: user.email,
      displayName: user.display_name,
    }
  })
}

const parseInvites = (invites: any): InvitedUser[] => {
  return invites.map((invite: any) => ({
    inviteId: invite.id,
    email: invite.email_address,
    status: invite.status,
  }))
}

const parseTeamMembers = (data: any): TeamMembers => {
  const members = parseMembers(data.members)
  const invites = parseInvites(data.invites)

  return { members, invites }
}

const parseInviteInformation = (data: any): InviteInformation => {
  return {
    companyName: data.company.company_name,
    email: data.email_address,
    companyId: data.company.id,
  }
}

const parseStructuredChannelMessage = (data: any): StructuredMessage => {
  return {
    id: data.id,
    authorName: data.author.display_name,
    timestamp: new Date(data.timestamp),
    structuredContents: data.structured_contents,
  }
}

const parseStructuredMessageChannelContents = (data: any): StructuredMessageChannelContents => {
  const channelMembers = parseMembers(data.channel_members)
  const messages = data.messages.map(parseStructuredChannelMessage)

  return {
    channelMembers,
    timeOrderedMessages: messages,
  }
}

const getOrThrow = async <T = any>(url: string): Promise<AxiosAdapterSuccessResponse<T>> => {
  const [resp, err] = await Api.get({ url })

  if (err) {
    const ex = new BackendError(err, 'GET', url)
    if (ex.isExceptional()) {
      Sentry.captureException(ex)
    }
    throw ex
  }
  return resp!
}

const getOrNullOrThrow = async <T = any>(url: string): Promise<T | null> => {
  try {
    const response = await getOrThrow<T>(url)
    return response.data
  } catch (ex) {
    if (ex instanceof BackendError) {
      if (ex.statusCode() === 404) {
        return null
      }
    }
    throw ex
  }
}

const postOrThrow = async <T = any>(
  url: string,
  payload: unknown = undefined,
): Promise<AxiosAdapterSuccessResponse<T>> => {
  const [resp, err] = await Api.post({ url, payload })

  if (err) {
    const ex = new BackendError(err, 'POST', url)
    if (ex.isExceptional()) {
      Sentry.captureException(ex)
    }
    throw ex
  }
  return resp!
}

export const getEngagement = async (engagementId: string): Promise<Engagement | null> => {
  const data = await getOrNullOrThrow(ENGAGEMENT_URL(engagementId))
  if (data === null) {
    return null
  }
  return parseEngagementPayload(data)
}

export const getPartneredEngagements = async (): Promise<Array<Engagement>> => {
  const resp = await getOrThrow(PARTNERED_ENGAGEMENTS_URL)
  return resp.data.map(parseEngagementPayload)
}

export const getBoxAccessToken = async (): Promise<string | null> => {
  try {
    const resp = await getOrThrow(BOX_TOKEN_URL)
    return resp.data
  } catch (Error) {
    console.warn('could not fetch box access token')
    return null
  }
}

export const getInviteInformation = async (token: string): Promise<InviteInformation> => {
  const resp = await getOrThrow(INVITE_INFORMATION_URL(token))

  return parseInviteInformation(resp.data)
}

export const getTeamMembers = async (companySlug: string): Promise<TeamMembers> => {
  const resp = await getOrThrow(TEAM_MEMBERS_URL(companySlug))

  return parseTeamMembers(resp.data)
}

export const getMessageChannelContents = async (
  channel: MessageChannelConnection,
): Promise<StructuredMessageChannelContents> => {
  const resp = await getOrThrow(MESSAGING_CHANNEL_URL(channel))

  return parseStructuredMessageChannelContents(resp.data)
}

const serializeContentBlock = (block: ComposedContentBlock): Record<string, any> => {
  switch (block.type) {
    case 'text':
      return { type: 'text', text: block.text }
    case 'mention':
      return { type: 'mention', subject_id: block.subjectId }
  }
}

export const serializeComposedMessage = (
  contents: ComposedContentBlock[],
): Record<string, any>[] => {
  return contents.map(serializeContentBlock)
}

export const postMessageToChannel = async (
  channel: MessageChannelConnection,
  messageContents: ComposedContentBlock[],
): Promise<void> => {
  const payload = { structured_contents: serializeComposedMessage(messageContents) }
  await postOrThrow(MESSAGING_CHANNEL_MESSAGES_URL(channel), payload)
}

export const sendCompanyInvite = async (
  companySlug: string,
  email: string,
  message: string,
): Promise<boolean> => {
  const payload = { email_address: email, message }
  const resp = await postOrThrow(SEND_INVITE_URL(companySlug), payload)

  // not sure what else we'd check here - it's a fire-and-forgot or else an exception
  return resp.statusCode === 201
}

type InviteAcceptValues = {
  password: string
  firstName: string
  lastName: string
  jobTitle: string
}

export const sendSignUpInviteAccept = async (
  values: InviteAcceptValues,
  token: string,
): Promise<UserAndCompany> => {
  const payload = {
    first_name: values.firstName,
    last_name: values.lastName,
    job_title: values.jobTitle,
    password: values.password,
  }

  const result = await postOrThrow<UserAndCompany>(ACCEPT_SIGNUP_INVITE_URL(token), payload)
  return result.data
}

export const resendCompanyInvite = async (inviteId: string): Promise<boolean> => {
  const resp = await postOrThrow(RESEND_INVITE_URL(inviteId))
  return resp.statusCode === 200
}

export const cancelCompanyInvite = async (inviteId: string): Promise<any> => {
  const resp = await postOrThrow(CANCEL_INVITE_URL(inviteId))

  return resp.statusCode === 200
}

export const updateTodoState = async (
  engagementId: string,
  todoKey: TodoKeysType,
  completed: boolean,
): Promise<TodoKeysType[]> => {
  const payload = { todo_key: todoKey, completed }
  const response = await postOrThrow<TodoKeysType[]>(
    COMPLETED_ENGAGEMENT_TODO_URL(engagementId),
    payload,
  )
  return response.data
}

export const sendNdaReminder = async (engagementId: string): Promise<Engagement> => {
  const response = await postOrThrow<Engagement>(SEND_NDA_REMINDER(engagementId))
  return response.data
}

export const updateEngagementStatus = async (
  engagementId: string,
  status: BrandEditableEngagementStages | ComanEditableEngagementStages,
): Promise<Engagement> => {
  const payload = { status }
  const response = await postOrThrow<Engagement>(
    UPDATE_ENGAGEMENT_STATUS_URL(engagementId),
    payload,
  )
  return response.data
}

export const getStripeCheckoutSession = async (
  payload: CheckoutPayload,
): Promise<CheckoutResponse> => {
  const response = await postOrThrow<any>(STRIPE_CHECKOUT_URL, payload)
  const { data: resp } = response

  return {
    id: resp.data.id,
    url: resp.data.url,
  }
}

export type BillingPortalDataType = {
  planId: string
  billingPortalSessionUrl: string | null
  isActivePaidSubscriber: boolean
  planName: string
  planExpiresAt: Date | null
}

export const getBillingPortalUrl = async (): Promise<BillingPortalDataType> => {
  const resp = await getOrThrow(BILLING_PORTAL_URL)

  const expiresAtDate = parseISO(resp.data.plan_expires_at)
  const planExpiresAt = isValid(expiresAtDate) ? expiresAtDate : null

  return {
    planId: resp.data.plan_id,
    billingPortalSessionUrl: resp.data.billing_portal_session_url,
    isActivePaidSubscriber: resp.data.is_active_paid_subscriber,
    planName: resp.data.plan_name,
    planExpiresAt,
  }
}
