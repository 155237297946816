// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* purgecss start ignore */\n\n.src-components-add-image-___styles__missingImage___ODyqp{\n  height:38px;\n  width:46px\n}\n\n.src-components-add-image-___styles__addImage___3oGGi{\n  min-width:100px !important;\n  width:40px !important\n}\n\n.src-components-add-image-___styles__addImage___3oGGi:focus{\n  box-shadow:none;\n  outline:none\n}\n\n.src-components-add-image-___styles__addImage___3oGGi:disabled{\n  background-color:transparent !important\n}\n\n.src-components-add-image-___styles__addImage___3oGGi:disabled span{\n  color:#e0e0e0 !important\n}\n\n.src-components-add-image-___styles__addImage___3oGGi span{\n  color:#2f80ed !important\n}\n\n#src-components-add-image-___styles__fileUpload___2VGeF{\n  display:none\n}\n\n/* purgecss end ignore */\n", "",{"version":3,"sources":["<no source>","webpack://styles.scss"],"names":[],"mappings":"AAAA,0BAAA;;ACKA;EACE,WAAY;EACZ;AAAW;;AACZ;EAGC,0BAA2B;EAC3B;AAAsB;;AAFxB;EAII,eAAgB;EAChB;AAAa;;AALjB;EASI;AAAwC;;AAT5C;EAWM;AAAuB;;AAX7B;EAgBI;AAA4B;;AAC7B;EAID;AAAa;;AD/Bf,wBAAA","sourcesContent":[null,"@import '../../../styles/colors';\n@import '../../../styles/metrics';\n@import '../../../styles/grid';\n@import '../../../styles/mixins';\n\n.missingImage {\n  height: 38px;\n  width: 46px;\n}\n\n.addImage {\n  min-width: 100px !important;\n  width: 40px !important;\n  &:focus {\n    box-shadow: none;\n    outline: none;\n  }\n\n  &:disabled {\n    background-color: transparent !important;\n    span {\n      color: $grey !important;\n    }\n  }\n\n  span {\n    color: $secondary !important;\n  }\n}\n\n#fileUpload {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#2b352f",
	"buttonShadowGrey": "rgba(102,102,102,0.25)",
	"darkerGrey": "#4f4f4f",
	"darkGrey": "#828282",
	"error": "#e52a2a",
	"fontBlack": "#333",
	"grey": "#e0e0e0",
	"greyBorder": "#f1f1f1",
	"lightestGreen": "#f4fcfa",
	"lightestGrey": "#fcfcfc",
	"lightGrey": "#f5f6fa",
	"middleGrey": "#a9a9a9",
	"primary": "#20cb9a",
	"primaryDark": "#111827",
	"secondary": "#2f80ed",
	"secondaryOpacity": "rgba(47,128,237,0.3)",
	"secondaryLightestOpacity": "rgba(47,128,237,0.1)",
	"success": "#20cb9a",
	"tertiary": "#56666e",
	"warning": "#e88f40",
	"white": "#fff",
	"buttonWidthMobile": "100px",
	"mobileBreakpoint": "600px",
	"bigspacer": "25px",
	"buttonHeight": "44px",
	"cardTitleFont": "18px",
	"inputHeight": "48px",
	"profilePhoto": "135px",
	"slimButtonHeight": "24px",
	"smallAction": "26px",
	"smallestFont": "12px",
	"smallestSpacer": "8px",
	"smallFont": "14px",
	"spacer": "15px",
	"standardFont": "16px",
	"subtitleFont": "16px",
	"titleFont": "26px",
	"missingImage": "src-components-add-image-___styles__missingImage___ODyqp",
	"addImage": "src-components-add-image-___styles__addImage___3oGGi",
	"fileUpload": "src-components-add-image-___styles__fileUpload___2VGeF"
};
export default ___CSS_LOADER_EXPORT___;
