// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* purgecss start ignore */\n\n.src-components-infinite-scroller-___styles__infinite___2b_Dk{\n  display:flex;\n  flex-direction:column;\n  height:calc(100vh - 250px);\n  overflow-x:hidden;\n  overflow-y:auto\n}\n\n.src-components-infinite-scroller-___styles__empty___2Oyuo{\n  align-items:center;\n  display:flex;\n  flex-direction:column;\n  height:calc(100vh - 250px);\n  justify-content:center\n}\n\n/* purgecss end ignore */\n", "",{"version":3,"sources":["<no source>","webpack://styles.scss"],"names":[],"mappings":"AAAA,0BAAA;;ACKA;EACE,YAAa;EACb,qBAAsB;EACtB,0BAA2B;EAC3B,iBAAkB;EAClB;AAAgB;;AACjB;EAGC,kBAAmB;EACnB,YAAa;EACb,qBAAsB;EACtB,0BAA2B;EAC3B;AAAuB;;ADlBzB,wBAAA","sourcesContent":[null,"@import '../../../styles/colors';\n@import '../../../styles/metrics';\n@import '../../../styles/grid';\n@import '../../../styles/mixins';\n\n.infinite {\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 250px);\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n\n.empty {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 250px);\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#2b352f",
	"buttonShadowGrey": "rgba(102,102,102,0.25)",
	"darkerGrey": "#4f4f4f",
	"darkGrey": "#828282",
	"error": "#e52a2a",
	"fontBlack": "#333",
	"grey": "#e0e0e0",
	"greyBorder": "#f1f1f1",
	"lightestGreen": "#f4fcfa",
	"lightestGrey": "#fcfcfc",
	"lightGrey": "#f5f6fa",
	"middleGrey": "#a9a9a9",
	"primary": "#20cb9a",
	"primaryDark": "#111827",
	"secondary": "#2f80ed",
	"secondaryOpacity": "rgba(47,128,237,0.3)",
	"secondaryLightestOpacity": "rgba(47,128,237,0.1)",
	"success": "#20cb9a",
	"tertiary": "#56666e",
	"warning": "#e88f40",
	"white": "#fff",
	"buttonWidthMobile": "100px",
	"mobileBreakpoint": "600px",
	"bigspacer": "25px",
	"buttonHeight": "44px",
	"cardTitleFont": "18px",
	"inputHeight": "48px",
	"profilePhoto": "135px",
	"slimButtonHeight": "24px",
	"smallAction": "26px",
	"smallestFont": "12px",
	"smallestSpacer": "8px",
	"smallFont": "14px",
	"spacer": "15px",
	"standardFont": "16px",
	"subtitleFont": "16px",
	"titleFont": "26px",
	"infinite": "src-components-infinite-scroller-___styles__infinite___2b_Dk",
	"empty": "src-components-infinite-scroller-___styles__empty___2Oyuo"
};
export default ___CSS_LOADER_EXPORT___;
