import { Company } from 'partnerslate-models'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'

import TestIds from '@/accessibility/test-ids'
import {
  CompanyCard,
  CompanySearchFilter,
  InfiniteScroller,
  Paper,
  SearchInput,
} from '@/components'
import type { CompanyFilter } from '@/components/company-search-filter'
import modal from '@/components/modal/modal-events'
import Strings, { Routes } from '@/constants'
import { useDebounce } from '@/helpers/hooks'
import mixpanel from '@/helpers/mixpanel'
import { endpoints } from '@/helpers/services'
import { ActionCreators, StoreState } from '@/redux'
import { searchCompaniesPayload } from '@/redux/api-payloads'
import { SearchedCompaniesStoreState } from '@/redux/company'
import selectors from '@/selectors'

import styles from './styles.scss'

type Props = {
  company: Company
  searchCompanies: (text: string, url?: string, filters?: Record<string, CompanyFilter>) => void
  searchData: SearchedCompaniesStoreState
  loading: boolean
}

const mapItem = (item: Company): React.ReactNode => (
  <CompanyCard key={item.id} company={item} testId={TestIds.Company.CompanyCard} />
)

function Search(props: Props) {
  const { searchCompanies, searchData, loading, company } = props
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 1000)
  const history = useHistory()
  const [filters, setFilters] = useState<Record<string, CompanyFilter>>({})
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    mixpanel.viewSearchPage()
  }, [])

  useEffect(() => {
    if (!isDirty) {
      searchCompanies(debouncedSearchText, undefined, filters)
      mixpanel.search()
    }
  }, [debouncedSearchText, filters, isDirty, searchCompanies])

  const redirectToCompanyProfile = () => {
    window.location.href = Routes.CompanyProfileSlug(company.slug)
    modal.remove()
  }
  const redirectToSubscribe = () => {
    history.push(Routes.ProfileSubscribe)
    modal.remove()
  }

  useEffect(() => {
    if (!company.subscription.isActive) {
      if (company.valid) {
        modal.renderModal('searchPaywall', {
          results: searchData.total,
          onCancel: redirectToCompanyProfile,
          onPremium: redirectToSubscribe,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.subscription.isActive, searchData.total])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  return (
    <main className={styles.searchGrid}>
      <section className={styles.search}>
        <Paper className={styles.paper}>
          <SearchInput
            className={styles.searchInput}
            placeholder={Strings.search.placeholder}
            name="companySearchInput"
            onChange={handleInputChange}
          />
          <InfiniteScroller
            getMore={() => {
              if (searchData.hasMore) {
                searchCompanies(searchText, searchData.nextURL, filters)
              }
            }}
            items={Object.values(searchData.results)}
            itemMapper={mapItem}
            hasMore={searchData.hasMore}
            loading={!searchData.loaded || loading}
          />
        </Paper>
      </section>
      <section className={styles.filters}>
        <CompanySearchFilter setFilters={setFilters} isDirty={isDirty} setIsDirty={setIsDirty} />
      </section>
    </main>
  )
}

const mapStateToProps = (state: StoreState) => {
  const user = selectors.user.getUser(state)
  return {
    company: selectors.company.getCompany(state, user.company),
    loading: selectors.api.getPending(state, endpoints.getCompanySearchResults),
    searchData: selectors.company.getCompanySearchData(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  searchCompanies: (text: string, url?: string, filters?: Record<string, CompanyFilter>) =>
    dispatch(
      ActionCreators.api.makeRequest.dispatch(searchCompaniesPayload({ text, url, filters })),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
