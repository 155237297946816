"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*  eslint-disable camelcase */
const _1 = require(".");
const defaults_1 = require("./defaults");
class Entitlements extends _1.BaseModel {
    constructor(data = {}) {
        super();
        this.can_send_connections = (0, defaults_1.defaultBoolean)(data.can_send_connections);
        this.should_see_trial_banner = (0, defaults_1.defaultBoolean)(data.should_see_trial_banner);
        this.trial_expires_at = (0, defaults_1.defaultDateOrNull)(data.trial_expires_at);
        this.connections_remaining = (0, defaults_1.defaultAnyNumberOrNull)(data.connections_remaining);
    }
    get canSendConnections() {
        return this.can_send_connections;
    }
    get shouldSeeTrialBanner() {
        return this.should_see_trial_banner;
    }
    get trialExpiresAt() {
        return this.trial_expires_at;
    }
    get connectionsRemaining() {
        var _a;
        return (_a = this.connections_remaining) !== null && _a !== void 0 ? _a : Infinity;
    }
}
exports.default = Entitlements;
