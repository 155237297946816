import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { CalendlyLink } from '@partnerslate/ui-components'
import React from 'react'

import { Routes } from '@/constants'
import { Engagement, EngagementParticipant, EngagementProject } from '@/domain/engagements'
import { scheduleCallViaCalendly } from '@/helpers/instrumentation'

function NameItem({ name }: { name: string }) {
  return (
    <ListItem disablePadding>
      <Typography variant="body1">{name}</Typography>
    </ListItem>
  )
}

function EmailItem({ email }: { email: string }) {
  return (
    <ListItem disablePadding>
      <Link href={`mailto:${email}`}>
        <Typography color="primary.main" variant="body2">
          {email}
        </Typography>
      </Link>
    </ListItem>
  )
}

function PhoneItem({ phone }: { phone: string | null }) {
  return (
    <ListItem disablePadding>
      <Link href={`tel:${phone}`}>
        <Typography color="primary.main" variant="body2">
          {phone}
        </Typography>
      </Link>
    </ListItem>
  )
}

type DetailProps = {
  company: EngagementParticipant
}

function CompanyDetails({ company }: DetailProps) {
  const { companyName, slug } = company
  return (
    <List sx={{ mb: 2 }}>
      <NameItem name={companyName} />
      <ListItem disablePadding>
        <Link aria-label={slug} href={Routes.CompanyProfileSlug(slug)}>
          <Typography color="primary.main" variant="body2">
            Company details
          </Typography>
        </Link>
      </ListItem>
    </List>
  )
}

function BrandDetails({ company }: DetailProps) {
  const { contactName, contactEmail, phone } = company
  return (
    <List sx={{ my: 2 }}>
      <NameItem name={contactName} />
      <EmailItem email={contactEmail} />
      <PhoneItem phone={phone} />
    </List>
  )
}

type ComanDetailProps = {
  engagement: Engagement
}

function ComanDetails({ engagement }: ComanDetailProps) {
  const { perspective } = engagement
  const { contactName, contactEmail, phone, calendlyUrl } = engagement.coman
  const isBrandPerspective: boolean = perspective === 'brand'

  return (
    <List sx={{ my: 2 }}>
      <NameItem name={contactName} />
      <EmailItem email={contactEmail} />
      <PhoneItem phone={phone} />
      {isBrandPerspective && calendlyUrl ? (
        <CalendlyLink
          href={calendlyUrl}
          onBeforeOpenHref={() => scheduleCallViaCalendly(engagement.id)}
        />
      ) : null}
    </List>
  )
}

type ProjectDetailProps = {
  project: EngagementProject
  onProjectDetailsClick: () => void
}

function ProjectDetails({ project, onProjectDetailsClick }: ProjectDetailProps) {
  const { name } = project
  return (
    <List sx={{ my: 2 }}>
      <ListItem disablePadding>
        <Typography variant="body1" onClick={onProjectDetailsClick}>
          {name}
        </Typography>
      </ListItem>
      <ListItem disablePadding sx={{ cursor: 'pointer' }} onClick={onProjectDetailsClick}>
        <Typography color="primary.main" variant="body2">
          Project details
        </Typography>
      </ListItem>
    </List>
  )
}

type SidebarProps = {
  engagement: Engagement
  showProjectDetailFn: () => void
}

export function Sidebar({ engagement, showProjectDetailFn }: SidebarProps): JSX.Element {
  const { perspective } = engagement

  return (
    <Box p={2}>
      {perspective === 'partner' ? (
        <>
          <CompanyDetails company={engagement.brand} />
          <Divider />
        </>
      ) : null}
      <ProjectDetails project={engagement.project} onProjectDetailsClick={showProjectDetailFn} />
      <Divider />
      {perspective === 'partner' ? (
        <BrandDetails company={engagement.brand} />
      ) : (
        <ComanDetails engagement={engagement} />
      )}
    </Box>
  )
}

export function SidebarAccordion({ engagement, showProjectDetailFn }: SidebarProps): JSX.Element {
  return (
    <Box px={{ sm: 4 }} py={2}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="contact-content">
          <Typography>Contact details</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Sidebar engagement={engagement} showProjectDetailFn={showProjectDetailFn} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
