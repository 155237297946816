// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* purgecss start ignore */\n\n.src-forms-reset-password-___styles__loginInputs___1HBf7{\n  align-items:center;\n  display:flex;\n  flex-direction:column;\n  justify-content:center\n}\n\n.src-forms-reset-password-___styles__loginInputs___1HBf7>div{\n  width:100%\n}\n\n.src-forms-reset-password-___styles__loginInputs___1HBf7 input{\n  width:inherit\n}\n\n.src-forms-reset-password-___styles__buttons___3xuzU{\n  align-items:center;\n  display:flex;\n  flex-direction:column\n}\n\n.src-forms-reset-password-___styles__buttons___3xuzU .src-forms-reset-password-___styles__submitButton___17dUp{\n  width:100%\n}\n\n/* purgecss end ignore */\n", "",{"version":3,"sources":["<no source>","webpack://styles.scss"],"names":[],"mappings":"AAAA,0BAAA;;ACKA;EACE,kBAAmB;EACnB,YAAa;EACb,qBAAsB;EACtB;AAAuB;;AAJzB;EAMI;AAAW;;AANf;EAUI;AAAc;;AACf;EAID,kBAAmB;EACnB,YAAa;EACb;AAAsB;;AAHxB;EAMI;AAAW;;ADzBf,wBAAA","sourcesContent":[null,"@import '../../../styles/colors';\n@import '../../../styles/metrics';\n@import '../../../styles/grid';\n@import '../../../styles/mixins';\n\n.loginInputs {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  > div {\n    width: 100%;\n  }\n\n  input {\n    width: inherit;\n  }\n}\n\n.buttons {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n\n  .submitButton {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#2b352f",
	"buttonShadowGrey": "rgba(102,102,102,0.25)",
	"darkerGrey": "#4f4f4f",
	"darkGrey": "#828282",
	"error": "#e52a2a",
	"fontBlack": "#333",
	"grey": "#e0e0e0",
	"greyBorder": "#f1f1f1",
	"lightestGreen": "#f4fcfa",
	"lightestGrey": "#fcfcfc",
	"lightGrey": "#f5f6fa",
	"middleGrey": "#a9a9a9",
	"primary": "#20cb9a",
	"primaryDark": "#111827",
	"secondary": "#2f80ed",
	"secondaryOpacity": "rgba(47,128,237,0.3)",
	"secondaryLightestOpacity": "rgba(47,128,237,0.1)",
	"success": "#20cb9a",
	"tertiary": "#56666e",
	"warning": "#e88f40",
	"white": "#fff",
	"buttonWidthMobile": "100px",
	"mobileBreakpoint": "600px",
	"bigspacer": "25px",
	"buttonHeight": "44px",
	"cardTitleFont": "18px",
	"inputHeight": "48px",
	"profilePhoto": "135px",
	"slimButtonHeight": "24px",
	"smallAction": "26px",
	"smallestFont": "12px",
	"smallestSpacer": "8px",
	"smallFont": "14px",
	"spacer": "15px",
	"standardFont": "16px",
	"subtitleFont": "16px",
	"titleFont": "26px",
	"loginInputs": "src-forms-reset-password-___styles__loginInputs___1HBf7",
	"buttons": "src-forms-reset-password-___styles__buttons___3xuzU",
	"submitButton": "src-forms-reset-password-___styles__submitButton___17dUp"
};
export default ___CSS_LOADER_EXPORT___;
